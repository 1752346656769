<template>
  <div class="svg_holder" :class="[color, size]">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 499.03 347.77"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path d="M296.67,89.53Q225,45.45,153.42,1.17c-2.48-1.53-4.27-1.59-6.73,0-8.27,5.31-16.69,10.36-25,15.52Q62.77,53,3.94,89.4c-1.68,1-3.65,3.27-3.72,5-.36,9.31-.17,18.64-.17,28.37h300c0-9.39-.14-18.38.08-27.36C300.2,92.46,299,91,296.67,89.53Z"/><path d="M.19,347.77H299.74V299.58H.19Z"/><path d="M499,211.17l-99.64-88.1v50.57H324.85V248.9h74.92V299Z"/><path d="M24.93,149.45V272.91H74.75V149.45Z"/><path d="M124.29,149.56v123.2h50.05V149.56Z"/><path d="M225.24,149.47v123.4h48.62V149.47Z"/></g></g></svg>
  </div>
</template>

<script>
export default {
  name: 'IconBankClearing',
  props: ['size', 'color']
}
</script>

<style scoped>
  .svg_holder {
    display: -webkit-flex; /* Safari */
    -webkit-align-items: center; /* Safari 7.0+ */
    display: flex;
    align-items: center;
    -webkit-justify-content:center;
    justify-content:center;
  }
</style>
